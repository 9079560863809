import React, { Component, useContext, useEffect } from 'react'
import {
  MemoryRouter as Router, Switch, Route, Link, Redirect,
} from 'react-router-dom'
import { Helmet } from "react-helmet"
import { store } from './Store'
import { useActions } from './Action'
import Header from './components/header'
import OrderDetails from './components/order/order-details'
import Footer from './components/footer'
import Login from './components/login'
import OrderSearch from './components/order/order-search'
import Loader from './components/loader'
import ChangePassword from './components/change-password'
import Logistics from './components/logistics/logistics'
import Reporting from './components/reports/reports'
import ForgotPassword from './components/forgot-password'
import Exceptions from './components/exception/search-exception'
import TagManager from 'react-gtm-module'
import { Config } from './config'

const GTMid = process.env.GTMid
const tagManagerArgs = {
  gtmId: GTMid
}

const INVOKE_ONCE = true;

const App = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const changeHeader = state && state.loginsuccess
  const resources = state && state.resources;
  const _isLoading = state && state.isLoading
  const isSubmitLoader = state && state.submitLoader
  TagManager.initialize(tagManagerArgs)
  useEffect(() => {
    actions.getResources()
    //actions.getMasterdata()
  }, [INVOKE_ONCE])
  return (
    <React.Fragment key="route">
      <div className="bg-white ">
        <Helmet>
          <meta
            name="robots"
            content={Config.APP.isProd ? "index,follow" : "noindex, nofollow"}
          />
          {!Config.APP.isProd && <meta name="googlebot" content="none" />}
        </Helmet>
        { (_isLoading || isSubmitLoader) && <Loader />}
        {resources && (
          <div>
            <Router>
              {changeHeader && <Header />}
              <Switch>
                <Route path="/orderSearch">
                  <OrderSearch />
                </Route>
                <Route path="/changepass">
                  <ChangePassword />
                </Route>
                <Route path="/forgotpass">
                  <ForgotPassword />
                </Route>
                <Route path="/logistics">
                  <Logistics />
                </Route>
                < Route path = "/reports" >
                  <Reporting />
                </Route>
                < Route path = "/exception" >
                  <Exceptions />
                </Route>
                <Route path="/">
                  <Login />
                </Route>
              </Switch>
              <Footer />
            </Router>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
export default App
