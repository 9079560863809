import React, { useContext } from 'react'
import Select from 'react-select'
import Validator from './schemas'
import { store } from '../Store'
import errorMessages from './error-messages'

import DatePicker from "react-datepicker";
const moment = require('moment')
//import '../styles/react-datepicker.css';

import sortInActiveDown from '../assets/sort_down_inactive.png'
import sortInActiveUP from '../assets/sort_up_inactive.png'
import sortActiveDown from '../assets/sort_down_active.png'
import sortActiveUP from '../assets/sort_up_active.png'


const LabeledInputField = ({
  type,
  name,
  value,
  label,
  hideLabel,
  placeholder,
  disabled,
  className,
  onChange,
  onClick,
  register,
  errors,
  schema,
  customeValidator,
  touched,
  dirtyFields,
  Mandatory,
  labelClass,
  defaultValue,
  restrictInput,
  onKeyPress,
  errorClass,
  onBlur,
  enableBlurEvent,
  autocomplete = 'on'
}) => {
  const { state } = useContext(store)
  const { resources } = state
  const validator = customeValidator || Validator(schema, name)

  console.log({value, defaultValue})
  return (
    <>
      {!hideLabel && (
        <label htmlFor={name} className={`${labelClass} text-base leading-19 font-VodafoneRg text-black`}>
          {resources && resources[label]}
          {Mandatory && <span className="text-red-1 ml-1">*</span>}
        </label>
      )}
      <input
        type={type}
        name={name}
        value={value}
        disabled={disabled}
        placeholder={resources && resources[placeholder]}
        className={`input_box ${className} ${errors[name] ? 'input_box_error' : ''}`}
        onChange={(e) => onChange(e.target.value)}
        ref={register(validator)}
        defaultValue={defaultValue}
        onKeyPress={(e) => restrictInput && onKeyPress(e)}
        onBlur={(e) => enableBlurEvent && onBlur(e.target.value)}
        autocomplete={autocomplete}
      />
      {errors[name] && errors[name].type == 'required' && <p className={`${errorClass ? errorClass : ''} error-text`}>{errorMessages('required', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'numeric' && <p className="error-text">{errorMessages('numeric', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'minLength' && <p className="error-text">{errorMessages('minLength', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'maxLength' && <p className="error-text">{errorMessages('maxLength', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'email' && <p className="error-text">{errorMessages('email', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'min' && <p className="error-text">{errorMessages('min', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'max' && <p className="error-text">{errorMessages('max', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'password' && <p className="error-text">{errorMessages('password', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'alpha' && <p className="error-text">{errorMessages('alpha', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'invalidnum' && <p className="error-text">{errorMessages('invalidnum', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'mobileFormat' && <p className="error-text">{errorMessages('mobileFormat', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'mobileLength1' && <p className="error-text">{errorMessages('mobileLength1', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'mobileLength2' && <p className="error-text">{errorMessages('mobileLength2', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'maxPostalCode' && <p className="error-text">{errorMessages('maxPostalCode', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'minPostalCode' && <p className="error-text">{errorMessages('minPostalCode', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'numericPostCode' && <p className="error-text">{errorMessages('numericPostCode', label, validator, resources)}</p>}
    </>
  )
}

export const RenderSelectField = ({
  options, removeDefaultOption, hideLabel, labelClass, optionsKey, optionsValue, register, onChange, label, className, name, Mandatory, disabled, multiple, value, errors, schema,
}) => {
  const { state } = useContext(store)
  const resources = state.resources
  const validator = Validator(schema, name)
  return (
    <>

      <label htmlFor={name} className={`${labelClass} text-base leading-19 font-VodafoneRg text-black`}>
        {resources && resources[label]}
        {' '}
        {Mandatory && <span className="text-red-1 ml-1">*</span>}
      </label>
      <select
        name={name}
        value={value}
        ref={register(validator)}
        className={`${className} select_box ${errors[name] ? 'input_box_error' : ''}`}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        ref={register(validator)}
      >
        {!removeDefaultOption && <option value="">Select</option>}
        {options.map((option, i) => (
          <option key={i} selected={value === option[optionsKey]} value={option[optionsKey]}>
            {option[optionsValue]}
          </option>
        ))}
      </select>
    </>
  )
}

// export const RenderSelectField = ({
//   options,
//   optionsLabel,
//   optionsValue,
//   register,
//   onChange,
//   label,
//   className,
//   name,
//   Mandatory,
//   disabled,
//   value,
//   errors,
//   schema,
//   hideLabel,
//   isSearchable,
//   placeholder,
//   labelClass
// }) => {
//   const { state } = useContext(store)
//   const { resources } = state
//   const validator = Validator(schema, name)
//   const displayOptions = []
//   options
//     && options.map((d) => {
//       displayOptions.push({
//         label: d[optionsLabel],
//         value: d[optionsValue],
//       })
//     })
//   return (
//     <>
//       {!hideLabel && (
//         <label htmlFor={name} className={`${labelClass} text-base leading-19 font-VodafoneRg text-black`}>
//           {resources && resources[label]}
//           {' '}
//           {Mandatory && <span className="text-red-1 ml-1">*</span>}
//         </label>
//       )}
//       <Select
//         className={`${className} select_box ${errors[name] ? 'input_box_error' : ''}`}
//         classNamePrefix="select"
//         isDisabled={disabled}
//         isClearable={false}
//         isSearchable={isSearchable}
//         name={name}
//         value={value}
//         // menuIsOpen={true}
//         placeholder={(resources && resources[placeholder]) || 'Select'}
//         options={displayOptions}
//         onChange={(e) => onChange(e)}
//         ref={register(validator)}
//       />
//     </>
//   )
// }
export default LabeledInputField


export const SortSelection = ({
  fieldName, sortOrder, onClick, className
}) => {
  return (
    <>
      <span className={`sortArrow ${className}`}>
        <span>
          <img className="pos-abs cursor-pointer" src={sortOrder == "ASC" ? sortActiveUP : sortInActiveUP} onClick={() => onClick('ASC', fieldName)}/>
        </span>
        <span className="pt-10">
          <img className="pos-abs cursor-pointer" src={sortOrder == "DESC" ? sortActiveDown : sortInActiveDown} onClick={() => onClick('DESC', fieldName)}/>
        </span>
      </span>
      
    </>
  )
}


export const DateInputField = ({
  value, onChange, placeholder, format
}) => {
  const { state } = useContext(store)
  const { resources } = state
  const range = (start, end, step = 1) => {
    const len = Math.floor((end - start) / step) + 1
    return Array(len).fill().map((_, idx) => start + (idx * step))
  }

  const years = range(1900, new Date().getFullYear() + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const setDate = (date, ) => {
      let pDate;
      if(date)
        pDate = moment(date).format('YYYY-MM-DD')
      onChange(pDate)
  }

  return (
    <>
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <select
              value={date && date.getFullYear()}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
  
            <select
              value={months[date && date.getMonth()]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
  
            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
        onChange={date => setDate(date)}
        placeholderText={resources && resources[placeholder]}
        selected={ value ? moment(value, 'YYYY-MM-DD').toDate() :''}
        dateFormat={format}
      />
      
    </>
  )
}